import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const OrderInvoice = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "user") {
        window.location.assign('/')
    }
    const order_id = useParams().id

    const [order, setOrder] = useState([]);
    const getOrder = async () => {
        const { data } = await axios.get(`/finance/print/header/${order_id}`);
        setOrder(data);
    }




    function formatDateTime() {
        // Create a new Date object for the current date and time
        const date = new Date();
    
        // Extract day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0'); // Ensures day is two digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1, and ensure two digits
        const year = date.getFullYear();
    
        // Extract hours, minutes, and seconds from the date object
        const hours = String(date.getHours()).padStart(2, '0'); // Ensure hours are two digits
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure minutes are two digits
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Ensure seconds are two digits
    
        // Combine date and time components in the desired format
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    
        // Return the formatted date and time
        return formattedDateTime;
    }
    
    // Usage example
    const formattedDateTime = formatDateTime();
    console.log(`<p>${formattedDateTime}</p>`);


    const [orderItem, setOrderItem] = useState([]);
    const getOrderItem = async () => {
        const { data } = await axios.get(`/finance/detailsafeinvouce/${order_id}`);
       setOrderItem(data);
  }

    useEffect(() => {
        const fetchData = async () => {
            await getOrder();
            await getOrderItem();
            $('.nav-item').removeClass('active');
            $('.nav-item').each(function () {
                if ($(this).find('a').attr('href') === window.location.pathname) {
                    $(this).addClass('active');
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
            // Automatically trigger print dialog after 1 second delay
            setTimeout(() => {
                window.print();
            }, 1000);
            setTimeout(() => {
                window.close();
            }, 3000);
        };

        fetchData();
    }, [order_id]);

    return (
        <div className="page-content">
            <style>{`
                @media print {
                    /* Adjusting font sizes, margins, paddings, etc. */
                    body {
                        font-size: 13pt; /* Adjust the font size as needed */
                        margin: 0; /* Remove default margins */
                        padding: 0; /* Remove default padding */
                        width: 80mm; /* Width of the paper (80 millimeters for 80mm thermal printer) */
                         /* Height can be set to auto to allow for content expansion */
                         overflow: hidden;
                    }
    
                    /* Adjusting the width of printed content */
                    .card {
                        width: 80mm !important; /* Set the width to fit on 80mm paper */
                        height 0;
                    }
    
                    /* Adjusting table size */
                    .table-sm td,
                    .table-sm th {
                        padding: 0.2rem !important; /* Adjust cell padding */
                        font-size: 13pt !important; /* Adjust font size */
                    }
    
                    /* Example: Hiding unnecessary elements */
                    .header,
                    .footer {
                        display: none; /* Hide header and footer when printing */
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                    /* Preventing page break before printing */
                    .print {
                        page-break-before: auto;
                    }
                }
            `}</style>
            <div className="card-body print">
                <div className="col-lg-12">
                    
                    <div className="card">
                 
                        <div className="row">
                       
                            <div className="container-fluid print">
                             
                                <div className="text-center">
                                <div className="text-center">
                                   
                                 <h1> Focus Car Wash </h1>
                               </div>
                                    <p>Date and Time: </p>
                                  
                                    <p>{formatDateTime()}</p>
                                    <p> -------------------------------- </p>
                                    <p>Invoice Number: {order.id}</p>
                                    <p> -------------------------------- </p>
                                    <p>{order.carmodel} </p>
                                    <p> -------------------------------- </p>
                              
                           
                                </div>
                                <div className="text-center">
                                    <h4>Service Details</h4>
                                </div>
                                
                                <div className="table-responsive">
                                    <table className="table table-sm text-center">
                                        <thead>
                                            <tr>
                                            <p> Price</p>
                                   <p>{order.amount}</p>
                                                                                          
                                               </tr>
                                        </thead>
                                      
                                    </table>
                                    <div style={{textAlign: 'left'}} className="text">
                                    <p> -------------------------------- </p>
                                    <p>Sub-Total: {commaNumber(order.amount)}</p>
                                    <p> ------- </p>
                                </div>
                                </div>
                              
                                
                                <div style={{textAlign: 'center'}}>
                                    <h4 style={{fontSize: '16px'}}>Location:</h4>
                                      <p  style={{fontSize: '14px'}} >Gulan Mall Garage- Turkish Visa Gate </p> 
                                      <p   style={{fontSize: '14px'}}>گەراجی گۆڵان مۆڵ-دەرگای فیزەی تورکی  </p>
                                      <p  style={{fontSize: '14px'}}> گراج گوڵان مۆڵ-بوابە فیزە ترکی</p>
                                      <p  style={{fontSize: '14px'}}>Mobile: 0750 365 60 60 </p>
                                    
                                </div>
                                <div className="text-center">
                                <p> -------------- </p>
                                    <small>Thanks For choosing us</small>
                                    <br />
                                    <small>سوپاس بۆ هەڵبژاردنی ئێمە</small>
                                    <br />
                                    <small>شکرا للاختیارنا</small>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

export default OrderInvoice;
