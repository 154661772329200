/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import Swal from "sweetalert2";

const Sales = ({ icon, translate, lang, role }) => {
    const { booking_id } = useParams();
    if (role !== "admin" && role !== "superuser" && role !== "user") {
        window.location.assign('/')
    }
    const [safe, setSafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/order/sale_header");
        setSafe(data);
        if (data.length > 0) {
            // axios.get("/datatable").then((ready) => {
            //     if (ready.data !== undefined) {
            //         if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
            //             window.$('.dt-tbl').DataTable();
            //         } else {
            //             window.$('.dt-tbl').DataTable({
            //                 responsive: true,
            //                 dom: 'Plfrtip',
            //                 searchPanes: {
            //                     initCollapsed: true
            //                 },
            //                 columnDefs: [
            //                     {
            //                         searchPanes: {
            //                             show: true
            //                         },
            //                         targets: [1, 2, 4, 5]
            //                     },
            //                     {
            //                         searchPanes: {
            //                             show: false
            //                         },
            //                         targets: []
            //                     },

            //                 ]
            //             })
            //         }
            //     }
            // });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };



    useEffect(() => {
        getcustomer()
        getSafe();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [customer, setcustomer] = useState([]);
    const getcustomer = async () => {
        const { data } = await axios.get('/customer/list');
        setcustomer(data.map((item) => {
            return {
                value: item.id,
                label: item.full_name_en
            };
        }));
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`account_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> {translate(`account_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "user") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>

                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`new_account_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/order/salesheader`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getSafe();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                        // Redirect to the detailsafe page with the ID of the newly added item
                                                        window.location.href = `/salesdetails/${res.data}`; // Assuming res.data contains the ID
                                                    }
                                                });
                                            }}>

                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="model_id" className="form-label">Customer List</label>
                                                            <Select options={customer} name="customer_id" placeholder={'Customer List'} required />
                                                        </div>
                                                    </div> 

                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_en" className="form-label">Description</label>
                                                        <input type="text" className="form-control" name="description" placeholder={'Description'} />
                                                    </div>

                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}   </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Customer</th>
                                        <th>Amount</th> 
                                        <th>Description</th>
                                        <th>Created</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {safe && safe.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.full_name_en}</td>
                                            <td>{item.amount}</td> 
                                            <td>{item.description}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>

                                            <td>
                                                {(role === "admin" || role === "user") && (
                                                    <>
                                                     
                                                        <span type="button" className="badge bg-info mx-2">
                                                            <a href={`/salesdetails/${item.id}`}> <icon.Info size="16" /></a>
                                                        </span>
                                                    </>
                                                )}
                                                <div className="modal fade" id={`update${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/order/salesheaderUPDT${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getSafe();
                                                                        window.$(`#update${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                  
                                                       <div className="mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="model_id" className="form-label">Customer List</label>
                                                            <Select options={customer} name="customer_id" placeholder={'Customer List'} required />
                                                        </div>
                                                    </div> 

                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_en" className="form-label">Description</label>
                                                        <input type="text" className="form-control" name="description" placeholder={'Description'} />
                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sales;