import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import Swal from "sweetalert2";

const Safe = ({ icon, translate, lang, role }) => {
    const { booking_id } = useParams();
    if (role !== "admin" && role !== "superuser" && role !== "user") {
        window.location.assign('/')
    }
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueDis, setSelectedValueDis] = useState('');
    const [safe, setSafe] = useState([]);
    const [modelCar, setModelCar] = useState([]);

    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setSafe(data);
        if (data.length > 0) {
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getmodelCar();
        getSafe();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const getmodelCar = async () => {
        const { data } = await axios.get('/Item/list');
        setModelCar(data.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        }));
    }

    
    function formatDate() {
        // Create a new Date object for the current date and time
        const date = new Date();
    
        // Extract day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0'); // Ensures day is two digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1, and ensure two digits
        const year = date.getFullYear();
    
        // Combine the date components in the desired format
        const formattedDate = `${day}/${month}/${year}`;
    
        // Return the formatted date
        return formattedDate;
    }
    
    // Usage example
    const formattedDate = formatDate();
    console.log(`<p>${formattedDate}</p>`);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        axios.post(`/finance/safe`, data).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                getSafe();
                e.target.reset();
                setSelectedValue('');
                window.$('#new').modal('hide');
                // window.open(`/order/invoice/${res.data}`, '_blank'); // Assuming res.data contains the ID
                var url = `/order/invoice/${res.data}`;
                window.open(url, '_blank');
            }
        });
    };

    const handleFromDateChange = (LabelValue) => {
        setSelectedValue(LabelValue.split('#')[1]);
        setSelectedValueDis(LabelValue.split('#')[1]);
    };

    const handleFromDateChangeDiscount = (LabelValue) => {
        if (LabelValue < 26) {
            var rate = LabelValue / 100;
            var result = selectedValue - (rate * selectedValue)
            setSelectedValueDis(result)
        }
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">وصل</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> وصل</h6>
                                </div>
                                {(role === "admin" || role === "user") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">اضافة

                                            </span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> وصل جديد </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={handleSubmit}>
                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="model_id" className="form-label">موديل السيارة</label>
                                                            <Select defaultValue={''} options={modelCar} name="model_id" placeholder={'موديل السيارة'} required />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_krd" className="form-label">السعر</label>
                                                        <input type="number" value={selectedValue}
                                                            onChange={(e) => setSelectedValue(e.target.value)} className="form-control" name="amount" placeholder={'السعر'} required />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                    <button type="submit" className="btn btn-primary"

                                                    > اضافة   </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="container py-4">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th>
                                            <div className="skeleton_wave" />
                                        </th>
                                        <th>
                                            <div className="skeleton_wave" /> </th>
                                        <th><div className="skeleton_wave" /></th>
                                        <th><div className="skeleton_wave" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><div className="skeleton_wave rounded" /></th>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                        <td><div className="skeleton_wave rounded" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                            <thead>
                                <tr>
                                    <th>رقم</th>
                                    <th>نوع السيارة</th>
                                    <th>السعر اجمالى</th>
                                    <th>الوقت</th>
                                    <th>خيارات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {safe && safe.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.carmodel}</td>
                                        <td>{item.amount}</td>
                                        <td>{new Date(item.start_date).toLocaleDateString()}</td>
                                        <td>
                                            {(role === "admin" || role === "user") && (
                                                <>
                                                    <span type="button" className="badge bg-warning mx-2" data-bs-toggle="modal" data-bs-target={`#update${item.id}`}>
                                                        <icon.Edit size="16" />
                                                    </span>
                                                    <span type="button" className="badge bg-info mx-2">
                                                        <a href={`/order/invoice/${item.id}`} target="_blank"> <icon.Printer size="16" /></a>
                                                    </span>
                                                </>
                                            )}
                                            <div className="modal fade" id={`update${item.id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/finance/safe/${item.id}`, data).then(res => {
                                                                if (isNaN(res.data)) {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    e.target.reset();
                                                                    getSafe();
                                                                    window.$(`#update${item.id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="model_id" className="form-label">موديل السيارة</label>
                                                                        <Select defaultValue={''} options={modelCar} name="model_id" placeholder={'موديل السيارة'} required />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="safe_name_krd" className="form-label">السعر</label>
                                                                    <input type="number" 
                                                                         className="form-control" name="amount" placeholder={'السعر'} required />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default Safe;
