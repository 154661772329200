/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import Select from 'react-select'

const Ahmad = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/expenses/list");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [1, 2, 3, 4]
                                },
                                

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };



    const [info, setInfo] = useState([]);
    useEffect(() => {
        axios.get('/info').then((res) => {
            setInfo(res.data);
        });
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

   

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`items_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`items_${lang}`)}</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    {(role === "admin" || role === "superuser") && (
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>
                                    )}
                                    <Link to="/groups" className="btn btn-sm btn-light ms-2">
                                        <span>{translate(`group_${lang}`)}</span>
                                        <icon.ChevronLeft size="20" className="mt-1" />
                                    </Link>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> {translate(`add_${lang}`)} </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/item/add`, data).then(res => {
                                                        if (isNaN(res.data)) {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            getData();
                                                            e.target.reset();
                                                            window.$('#new').modal('hide');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label">{translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                    <input type="text" name="name" className="form-control" placeholder={translate(`name_${lang}`)} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                        <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Model</th>
                                        <th>Created</th>
                                        <th>Updated</th>
                                        <th>#</th>
                                        <th>Model</th>
                                        <th>Created</th>
                                        <th>Updated</th>
                                        <th>Updated</th>    
                                        <th>{translate(`options_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.booking_id}</td>
                                            <td>{item.service_id}</td>
                                            <td>{item.cost}</td>
                                            <td>{item.discount}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.notes}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>{new Date(item.updated).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser") && (
                                                    <>
                                                        <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size={16} />
                                                        </span>
                                                    </>
                                                )}

                                            </td>
                                            <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog ">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">{translate(`edit_${lang}`)} </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/item/update/${item.id}`, data).then(res => {
                                                                if (isNaN(res.data)) {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    getData();
                                                                    e.target.reset();
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">{translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                            <input type="text" defaultValue={item.name} name="name" className="form-control" placeholder={translate(`name_${lang}`)} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                <button type="submit" className="btn btn-primary"> {translate(`update_${lang}`)}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Ahmad;