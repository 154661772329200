/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'

const SalesDetails = ({ icon, translate, lang, role }) => {
    const { booking_id } = useParams();
    if (role !== "admin" && role !== "superuser" && role !== "user") {
        window.location.assign('/')
    }
    const [safe, setSafe] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueDis, setSelectedValueDis] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const getSafe = async () => {
        let obj = { booking_id };
        const { data } = await axios.get(`/order/sale_details`, { params: { obj } });
        setSafe(data);
        setIsDataLoaded(true);
        if (data.length > 0) {
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        // Simulate click event on the add button when the page is loaded
        const addButton = document.querySelector('.btn-primary[data-bs-toggle="modal"][data-bs-target="#new"]');
        if (addButton) {
            addButton.click();
        }
    }, []);

    useEffect(() => {
        getmodelCar()
        getSafe();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const handleFromDateChange = (LabelValue) => {
        setSelectedValue(LabelValue.split('#')[1]);
        setSelectedValueDis(LabelValue.split('#')[1]);
    };
    const handleFromDateChangeDiscount = (LabelValue) => {
        var result = selectedValue * LabelValue
        setSelectedValueDis(result)

    };

    const [modelCar, setmodelCar] = useState([]);
    const getmodelCar = async () => {
        const { data } = await axios.get('/Item/Productlist');
        setmodelCar(data.map((item) => {
            return {
                value: item.id,
                label: item.barcode + '|' + item.item_name +'#'+item.sale_price
            };
        }));
    }

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`account_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> {translate(`account_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "user") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>
                                        <span style={{ marginRight: '5px' }}>
                                            {/* <button className="btn btn-sm btn-primary" onClick={handlePrint}>
                                                <icon.Printer size="20" />
                                                <span className="ms-2">{translate(`print_${lang}`)}</span>
                                            </button> */}
                                            <a href={`/order/salesinvoice/${booking_id}`} target={'_blank'} className="dropdown-item" onClick={() => {
                                                const newWindow = window.open(`/order/salesinvoice/${booking_id}`, '_blank');
                                                setTimeout(() => {
                                                    newWindow.print();
                                                }, 1000); // 1000 milliseconds = 1 second delay
                                            }}>
                                                <icon.Printer size={16} />
                                                <span className="mx-2">{translate(`print_${lang}`)}</span>
                                            </a>

                                        </span>


                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`new_account_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>

                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/order/sale_detailsAPI`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getSafe();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>

                                                <div className="modal-body">
                                                    <div className="mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="model_id" className="form-label">Items</label>
                                                            <Select
                                                                options={modelCar}
                                                                name="item_id"
                                                                placeholder={'Select Items ...'} 
                                                                required
                                                                onChange={(selectedOption) => {
                                                                    handleFromDateChange(selectedOption.label); // Use selectedOption.value directly
                                                                }} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_krd" className="form-label">Price</label>
                                                        <input type="hidden" name='sale_header_id' value={booking_id} />
                                                        <input type="number" value={selectedValue}
                                                            onChange={(e) => setSelectedValue(e.target.value)} className="form-control" name="price" placeholder={'Price'} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_krd" className="form-label">Quantity</label>
                                                        <input type="number"
                                                            onChange={(e) => handleFromDateChangeDiscount(e.target.value)} className="form-control" name="qty" placeholder={'Quantity'} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="safe_name_krd" className="form-label">Amount</label>
                                                        <input type="number" value={selectedValueDis}
                                                            onChange={(e) => setSelectedValue(e.target.value)} disable className="form-control" name="amount" placeholder={'Amount'} required />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Item</th>
                                        <th>Barcode</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        <th>Created</th>
                                        <th>Options</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {safe && safe.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.item_name}</td>
                                            <td>{item.barcode}</td>
                                            <td>{item.price}</td>
                                            <td>{item.qty}</td>
                                            <td>{item.amount}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "user") && (
                                                    <>
                                                        <span type="button" className="badge bg-danger" onClick={() => {
                                                            axios.delete(`/order/sale_details/${item.id}`).then(res => {
                                                                getSafe();
                                                            });
                                                        }}>
                                                            <icon.Trash />
                                                        </span>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesDetails;