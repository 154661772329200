import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";

const Dashboard = ({ icon, translate, lang, role }) => {
    const [st_orders, setst_orders] = useState([]);
    const [st_expensesalesData, setst_expensesales] = useState([]);
    const [st_bookingsales, setst_bookingsales] = useState([]);
    const [st_sales, setst_sales] = useState([]);
    const [st_serviceamount, setst_serviceamount] = useState([]);
    const [st_countservice, setst_countservice] = useState([]);
    const [st_income_v, setst_income_v] = useState([]);
    useEffect(() => {
        // Only fetch data if the user has the admin role
                if (role === "admin"  || role==="user") {
                    getData();
                    getData1();
                    getData2();
                    getData3();
                    getData4();
                    getData5();
                    getData6();
                }else {
                    getData();
                    getData1();

                }
            }, [role]);
    const getData = async () => {
        const { data } = await axios.get("/order/st_orders");
        await setst_orders(data);

    };
    const getData1 = async () => {
        const { data } = await axios.get("/order/st_expensesales");
        await setst_expensesales(data);
    };
    const getData2 = async () => {
        const { data } = await axios.get("/order/st_bookingsales");
        await setst_bookingsales(data);
    };
    const getData3 = async () => {
        const { data } = await axios.get("/order/st_sales");
        await setst_sales(data);
    };
    const getData4 = async () => {
        const { data } = await axios.get("/order/st_serviceamount");
        await setst_serviceamount(data);
    };
    const getData5 = async () => {
        const { data } = await axios.get("/order/st_countservice");
        await setst_countservice(data);
    };
    const getData6 = async () => {
        const { data } = await axios.get("/order/st_income_v");
        await setst_income_v(data);
    };
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`stats_${lang}`)}</li>
                </ol>
            </nav>
            {/* row center content */}
            {role === "admin" && (
            <div className="row ">
                <div className="col-md-8">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Daily Ticket - Amount</span>
                                                <h3 className="card-title mb-2">{st_serviceamount.serviceamount}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Daily Ticket - Count</span>
                                                <h3 className="card-title mb-2">{st_countservice.servicecount}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Order</span>
                                                <h3 className="card-title mb-2">{st_orders.orders}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Expneses</span>
                                                <h3 className="card-title mb-2">{st_expensesalesData.expense_sales}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">income</span>
                                                <h3 className="card-title mb-2">{st_income_v.income_sales}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Service</span>
                                                <h3 className="card-title mb-2">{st_bookingsales.booking_sales}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Sales</span>
                                                <h3 className="card-title mb-2">{st_sales.sales}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">Revinue</span>
                                                <h3 className="card-title mb-2">{st_bookingsales.booking_sales + st_income_v.income_sales + st_sales.sales - st_orders.orders - st_expensesalesData.expense_sales}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    {/* {chartData.series && (
                                        <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">{translate(`sale_list_${lang}`)}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
             {role === "user" && (
            <div className="row ">
                <div className="col-md-8">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">التذكرة اليومية - المبلغ</span>
                                                <h3 className="card-title mb-2">{st_serviceamount.serviceamount}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">التذكرة اليومية - العد  د</span>
                                                <h3 className="card-title mb-2">{st_countservice.servicecount}</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                           
                        </div>
                     
                    </div>
                </div>
              
            </div>
            )}
            
        </div>

    )
}

export default Dashboard;