import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const SalesInvoice = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "user") {
        window.location.assign('/')
    }
    const order_id = useParams().id

    const [order, setOrder] = useState([]);
    const getOrder = async () => {
        const { data } = await axios.get(`/order/print/header/${order_id}`);
        setOrder(data);
        console.log(order)
    }

    const [orderItem, setOrderItem] = useState([]);
    const getOrderItem = async () => {
        const { data } = await axios.get(`/order/detailsalesinvoiceprint/${order_id}`);
        setOrderItem(data);
    }

    useEffect(() => {
        getOrder();
        getOrderItem();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
        // eslint-disable-next-line
    }, [order_id]);

    return (
        <div className="page-content">
            <style>{`
                @media print {
                    /* Adjusting font sizes, margins, paddings, etc. */
                    body {
                        font-size: 16pt; /* Adjust the font size as needed */
                        margin: 0; /* Remove default margins */
                        padding: 0; /* Remove default padding */
                        width: 80mm; /* Width of the paper (80 millimeters for 80mm thermal printer) */
                         /* Height can be set to auto to allow for content expansion */
                    }
    
                    /* Adjusting the width of printed content */
                    .card {
                        width: 80mm !important; /* Set the width to fit on 80mm paper */
                        height 0;
                    }
    
                    /* Adjusting table size */
                    .table-sm td,
                    .table-sm th {
                        padding: 0.2rem !important; /* Adjust cell padding */
                        font-size: 16pt !important; /* Adjust font size */
                    }
    
                    /* Example: Hiding unnecessary elements */
                    .header,
                    .footer {
                        display: none; /* Hide header and footer when printing */
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                    /* Preventing page break before printing */
                    .print {
                        page-break-before: auto;
                    }
                }
            `}</style>
            <div className="card-body print">
                <div className="col-lg-12">
                    
                    <div className="card">
                 
                        <div className="row">
                       
                            <div className="container-fluid print">
                             
                                <div className="text-center">
                                <div className="text-center">
                                   
                                   <img src="../../../../../assets/img/logo404.png" alt="Logo" className="noble-ui-logo" width={'230'} height={'150'} />
                                   <p>  </p>
                               </div>
                                    <p>Date and Time: </p>
                                  
                                    <p>{new Date().toLocaleString()}</p>
                                    <p> -------------------------------- </p>
                                    <p>Invoice Number: {order.id}</p>
                                    <p> -------------------------------- </p>
                                    <p>{order.full_name_en} </p>
                                    <p> -------------------------------- </p>
                                   
                           
                                </div>
                                <div className="text-center">
                                    <h4>Item Details</h4>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sm text-center">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th>Amount</th>
                                             
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderItem.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.amount}</td> 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div style={{textAlign: 'left'}} className="text">
                                    <p> -------------------------------- </p>
                                    <p>Sub-Total: {commaNumber(order.amount)}</p> 
                                </div>
                                </div>
                              
                                
                                <div style={{textAlign: 'right'}}>
                                    <h4 style={{fontSize: '16px'}}>Location:</h4>
                                      <p  style={{fontSize: '14px'}} >Gulan Mall Garage- Turkish Visa Gate </p> 
                                      <p   style={{fontSize: '14px'}}>گەراجی گۆڵان مۆڵ-دەرگای فیزەی تورکی  </p>
                                      <p  style={{fontSize: '14px'}}> گراج گوڵان مۆڵ-بوابە فیزە ترکی</p>
                                    
                                </div>
                                <div className="text-center">
                                <p> -------------- </p>
                                    <small>Thanks For choosing us</small>
                                    <br />
                                    <small>سوپاس بۆ هەڵبژاردنی ئێمە</small>
                                    <br />
                                    <small>شکرا للاختیارنا</small>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
    
    
    
}

export default SalesInvoice;