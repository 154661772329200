import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import Select from 'react-select'
import commaNumber from 'comma-number';

const List = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "productions") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/contract/list?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get('/contract/list');
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            order: [[0, 'desc']],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        console.log("Could not parse: ", i);
                                        return 0;
                                    }
                                    return parsed;
                                };
                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    },
                                    "د.ع": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    }
                                };

                                var columns = [3, 4, 5, 6, 7];
                                var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });

                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });
                            },
                        })

                    }

                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            },
                            "د.ع": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            }
                        };

                        var columns = [3, 4, 5, 6, 7];
                        var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });

                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }


    const [buyer, setBuyer] = useState([]);
    const getBuyer = async () => {
        const { data } = await axios.get('/customer/buyer');
        setBuyer(data.map((item) => {
            return {
                value: item.id,
                label: item[`full_name_${lang}`],
            };
        }));
    }

    const [safe, setsafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafe(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name_${lang}`]}`
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }

    useEffect(() => {
        getData();
        getBuyer();
        getSafe();
        getCurrency();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        // eslint-disable-next-line 
    }, []);

    const [thisContract, setThisCountract] = useState([])
    const [thisProductions, setThisProductions] = useState([])
    const [thisServices, setThisServices] = useState([])
    const [thisPayments, setThisPayments] = useState([])
    const [thisTransfer, setThisTransfer] = useState([])

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`contract_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`contract_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`add_${lang}`)}</span>
                                        </button>
                                    </div>
                                )}
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`add_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/contract/add`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getData();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <label className="form-label"> {translate(`buyer_${lang}`)}</label>
                                                            <Select options={buyer} name="customer_id" placeholder={translate(`buyer_${lang}`)} required />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`kurdi`)})</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder={translate(`kurdi`)} />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`arabic`)})</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder={translate(`arabic`)} />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`english`)})</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder={translate(`english`)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                $('input[name="rate"]').val(e.rate);
                                                                $('#crc').text(e.label)
                                                            }} />
                                                            <input type="hidden" name="rate" required />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`budget_${lang}`)}</label>
                                                            <input type="number" className="form-control" id="amount" name="amount" placeholder="0.00" />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`advanced_payment_${lang}`)} {translate(`by_percent_${lang}`)}</label>
                                                            <div className="input-group flex-nowrap">
                                                                <input type="number" className="form-control" id="per" min={0} step="any" defaultValue={0} onChange={(e) => {
                                                                    const amount = parseFloat($('#amount').val());
                                                                    const total = amount * (e.target.value / 100);
                                                                    $('#prepaid').val(total)
                                                                    $('#amt').val(total)
                                                                }} />
                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`advanced_payment_${lang}`)} {translate(`by_amount_${lang}`)}</label>
                                                            <div className="input-group flex-nowrap">
                                                                <input type="number" className="form-control" id="amt" min={0} step="any" defaultValue={0} onChange={(e) => {
                                                                    const amount = parseFloat($('#amount').val());
                                                                    const percent = (e.target.value / amount) * 100;
                                                                    const total = amount * (percent / 100);
                                                                    $('#prepaid').val(total);
                                                                    $('#per').val(percent);
                                                                }} />
                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1" id="crc"></span></span>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" id="prepaid" name="prepaid" placeholder="0.00" />
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`from_date_${lang}`)}</label>
                                                            <input type="date" className="form-control" name="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label"> {translate(`to_date_${lang}`)}</label>
                                                            <input type="date" className="form-control" name="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label"> تقدير التكلفة</label>
                                                            <input type="number" step={"any"} className="form-control" name="estimate_cost" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-striped print table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>{translate(`invoice_${lang}`)}</th>
                                        <th>{translate(`single_contract_${lang}`)}</th>
                                        <th>{translate(`customers_${lang}`)}</th>
                                        <th>تقدير التكلفة</th>
                                        <th>{translate(`budget_${lang}`)}</th>
                                        <th>{translate(`advanced_payment_${lang}`)}</th>
                                        <th>{translate(`paid_${lang}`)}</th>
                                        <th>{translate(`due_${lang}`)}</th>
                                        <th>{translate(`cost_${lang}`)}</th>
                                        <th>مصاريف</th>
                                        <th>{translate(`from_date_${lang}`)}</th>
                                        <th>{translate(`to_date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td><span type="button" className="text-danger" data-bs-toggle="modal" data-bs-target="#group" onClick={(() => {
                                                axios.get(`/contract/partner/${item.id}`).then((res) => {
                                                    setThisCountract(res.data)
                                                })
                                                axios.get(`/contract/productions/${item.id}`).then((res) => {
                                                    setThisProductions(res.data)
                                                })
                                                axios.get(`/contract/service/${item.id}`).then((res) => {
                                                    setThisServices(res.data)
                                                })
                                                axios.get(`/contract/payment/${item.id}`).then((res) => {
                                                    setThisPayments(res.data)
                                                })
                                                axios.get(`/contract/transfer/${item.id}`).then((res) => {
                                                    setThisTransfer(res.data)
                                                })
                                            })}
                                            ><icon.ChevronUp size={16} /> {item[`title_${lang}`]}</span></td>
                                            <td>{item[`full_name_${lang}`]}</td>
                                            <td>
                                                {commaNumber(item.estimate_cost)} {item.symbol}
                                            </td>
                                            <td>{commaNumber(item.amount)} {item.symbol}</td>
                                            <td>{commaNumber(item.prepaid)} {item.symbol}</td>
                                            <td>{commaNumber(item.amount_paid.toFixed(2))} {item.symbol}</td>
                                            <td> {item.amount_due > 0 ? <span className="text-danger">{commaNumber(item.amount_due.toFixed(2))}</span> : commaNumber(item.amount_due.toFixed(2))} {item.symbol}</td>
                                            <td>{commaNumber(item.total_cost.toFixed(2))} $</td>
                                            <td>{item.total_expenses}{item.symbol}</td>
                                            <td>{new Date(item.from_date).toLocaleDateString()}</td>
                                            <td>{new Date(item.to_date).toLocaleDateString()}</td>
                                            <td>
                                                {(role === "admin" || role === "superuser" || role === "operations" || role === "productions") && (
                                                    <>
                                                        <div className="dropdown">
                                                            <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <icon.MoreVertical size={16} />
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <span type="button" className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#cntr${item.id}`}>
                                                                    <icon.Percent size={16} />
                                                                    <span className="mx-2">{translate(`partner_${lang}`)}</span>
                                                                </span>
                                                                <span type="button" className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#srv${item.id}`}>
                                                                    <icon.Tool size={16} />
                                                                    <span className="mx-2">{translate(`receipt_${lang}`)}</span>
                                                                </span>
                                                                {item.amount_due > 0 &&
                                                                    <span type="button" data-bs-toggle="modal" data-bs-target={`#payment${item.id}`} className="dropdown-item">
                                                                        <icon.CreditCard size={16} />
                                                                        <span className="mx-2">{translate(`contract_payment_${lang}`)}</span>
                                                                    </span>
                                                                }
                                                                <span type="button" className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>                                                            <icon.Edit size={16} />
                                                                    <span className="mx-2">{translate(`edit_${lang}`)}</span>
                                                                </span>
                                                                <a href={`/contracts/printcontract/${item.id}`} className="badge bg-primary mx-1" target={'_blank'} rel="noreferrer">
                                                                    <icon.Printer size="16" />
                                                                </a>
                                                                <span type="button" className="dropdown-item" onClick={() => {
                                                                    Swal.fire({
                                                                        title: translate(`warning_${lang}`),
                                                                        text: translate(`warning_text_${lang}`),
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: translate(`yes_${lang}`),
                                                                        cancelButtonText: translate(`no_${lang}`)
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/contract/all/${item.id}`).then(res => {
                                                                                res.data === "done" ? getData() : toast.error(res.data);
                                                                            })
                                                                        }
                                                                    })
                                                                }} >
                                                                    <icon.Trash size={16} />
                                                                    <span className="mx-2">{translate(`delete_${lang}`)}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/contract/update/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        e.target.reset();
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="form-label"> {translate(`buyer_${lang}`)}</label>
                                                                            <Select options={buyer} name="customer_id" defaultValue={{ value: item.customer_id, label: item[`full_name_${lang}`] }} placeholder={translate(`buyer_${lang}`)} required />
                                                                        </div>
                                                                        <div className="col-md-4 mb-3">
                                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`kurdi`)})</label>
                                                                            <input type="text" className="form-control" name="title_krd" defaultValue={item.title_krd} placeholder={translate(`kurdi`)} />
                                                                        </div>
                                                                        <div className="col-md-4 mb-3">
                                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`arabic`)})</label>
                                                                            <input type="text" className="form-control" name="title_ar" defaultValue={item.title_ar} placeholder={translate(`arabic`)} />
                                                                        </div>
                                                                        <div className="col-md-4 mb-3">
                                                                            <label className="form-label"> {translate(`title_${lang}`)} ({translate(`english`)})</label>
                                                                            <input type="text" className="form-control" name="title_en" defaultValue={item.title_en} placeholder={translate(`english`)} />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                            <Select options={currency} name="currency_id" defaultValue={{ value: item.currency_id, label: item.symbol }} placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                                $('input[name="rate"]').val(e.rate);
                                                                                $(`#crc${item.id}`).text(e.label)
                                                                            }} />
                                                                            <input type="hidden" defaultValue={item.rate} name="rate" required />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`budget_${lang}`)}</label>
                                                                            <input type="number" className="form-control" defaultValue={item.amount} id={`amount${item.id}`} name="amount" placeholder="0.00" />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`advanced_payment_${lang}`)} {translate(`by_percent_${lang}`)}</label>
                                                                            <div className="input-group flex-nowrap">
                                                                                <input type="number" className="form-control" id={`per${item.id}`} min={0} step="any" defaultValue={(item.prepaid / item.amount) * 100} onChange={(e) => {
                                                                                    const amount = parseFloat($(`#amount${item.id}`).val());
                                                                                    const total = amount * (e.target.value / 100);
                                                                                    $(`#prepaid${item.id}`).val(total)
                                                                                    $(`#amt${item.id}`).val(total)
                                                                                }} />
                                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`advanced_payment_${lang}`)} {translate(`by_amount_${lang}`)}</label>
                                                                            <div className="input-group flex-nowrap">
                                                                                <input type="number" className="form-control" id={`amt${item.id}`} min={0} step="any" defaultValue={item.prepaid} onChange={(e) => {
                                                                                    const amount = parseFloat($(`#amount${item.id}`).val());
                                                                                    const percent = (e.target.value / amount) * 100;
                                                                                    const total = amount * (percent / 100);
                                                                                    $(`#prepaid${item.id}`).val(total);
                                                                                    $(`#per${item.id}`).val(percent);
                                                                                }} />
                                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1" id={`crc${item.id}`}>{item.symbol}</span></span>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" id={`prepaid${item.id}`} name="prepaid" defaultValue={item.prepaid} placeholder="0.00" />
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`from_date_${lang}`)}</label>
                                                                            <input type="date" className="form-control" name="from_date" defaultValue={new Date().toISOString(item.from_date).split('T')[0]} />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`to_date_${lang}`)}</label>
                                                                            <input type="date" className="form-control" name="to_date" defaultValue={new Date().toISOString(item.to_date).split('T')[0]} />
                                                                        </div>
                                                                        <div className="col-md-4 mb-3">
                                                                            <label className="form-label"> تقدير التكلفة</label>
                                                                            <input type="number" step={"any"} className="form-control"  defaultValue={item.estimate_cost} name="estimate_cost" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`cntr${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`new_contract_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.post(`/contract/partner`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        e.target.reset();
                                                                        window.$(`#cntr${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label"> {translate(`safe_name_${lang}`)}</label>
                                                                                <Select options={safe} name="safe_id" placeholder={translate(`safe_name_${lang}`)} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label className="form-label"> {translate(`percent_${lang}`)}</label>
                                                                                <div className="input-group flex-nowrap">
                                                                                    <input type="number" className="form-control" name="rate" placeholder="0.00" required />
                                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label"> {translate(`note_${lang}`)}</label>
                                                                                <textarea className="form-control" name="note" placeholder={translate(`note_${lang}`)} required />
                                                                            </div>
                                                                            <input type="hidden" name="contract_id" defaultValue={item.id} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`srv${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`receipt_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.post(`/contract/service-header`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        $(`#contract_service_header_${item.id}`).val(res.data)
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                        <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> {translate(`title_${lang}`)}</label>
                                                                        <textarea className="form-control" name="description" placeholder={translate(`note_${lang}`)} required />
                                                                        <input type="hidden" name="contract_id" defaultValue={item.id} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#srv2${item.id}`} data-bs-dismiss="modal"> {translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`srv2${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`receipt_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.post(`/contract/service-content`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getData();
                                                                        e.target.reset();
                                                                        axios.get(`/contract/service/${item.id}?hid=${$(`#contract_service_header_${item.id}`).val()}`).then((res) => {
                                                                            setThisServices(res.data)
                                                                        })
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <table className="table table-striped table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>{translate(`single_contract_${lang}`)}</th>
                                                                                <th>{translate(`note_${lang}`)}</th>
                                                                                <th>{translate(`cost_${lang}`)}</th>
                                                                                <th>{translate(`date_${lang}`)}</th>
                                                                                <th>{translate(`options_${lang}`)}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {thisServices.map((i, x) => (
                                                                                <tr key={x}>
                                                                                    <td>{x + 1}</td>
                                                                                    <td>{i[`title_${lang}`]}</td>
                                                                                    <td>{i.description}</td>
                                                                                    <td>{i.cost} {i.symbol}</td>
                                                                                    <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                                    <td>
                                                                                        <span type="button" className="badge bg-danger" onClick={() => {
                                                                                            Swal.fire({
                                                                                                title: translate(`warning_${lang}`),
                                                                                                text: translate(`warning_text_${lang}`),
                                                                                                icon: 'warning',
                                                                                                showCancelButton: true,
                                                                                                confirmButtonColor: '#3085d6',
                                                                                                cancelButtonColor: '#d33',
                                                                                                confirmButtonText: translate(`yes_${lang}`),
                                                                                                cancelButtonText: translate(`no_${lang}`)
                                                                                            }).then((result) => {
                                                                                                if (result.isConfirmed) {
                                                                                                    axios.delete(`/contract/service-content/${i.id}`).then(res => {
                                                                                                        axios.get(`/contract/service/${item.id}?hid=${$(`#contract_service_header_${item.id}`).val()}`).then((res) => {
                                                                                                            setThisServices(res.data)
                                                                                                        })
                                                                                                    })
                                                                                                }
                                                                                            })
                                                                                        }} >
                                                                                            <icon.Trash size={16} />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <input type="hidden" name="contract_service_header_id" id={`contract_service_header_${item.id}`} required />
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                        <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                            $('input[name="rate"]').val(e.rate);
                                                                        }} />
                                                                        <input type="hidden" name="rate" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> {translate(`cost_${lang}`)}</label>
                                                                        <input type="number" className="form-control" name="cost" placeholder="0.00" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> {translate(`note_${lang}`)}</label>
                                                                        <textarea className="form-control" name="description" placeholder={translate(`note_${lang}`)} required />
                                                                        <input type="hidden" name="contract_id" defaultValue={item.id} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`payment${item.id}`} tabIndex={-1} aria-labelledby="payment" aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`contract_payment_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.contract_id = item.id;
                                                                axios.post(`/contract/payment`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        window.$(`#payment${item.id}`).modal('hide')
                                                                        e.target.reset();
                                                                        getData();
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="due" className="col-form-label">{translate(`amount_due_${lang}`)}:</label>
                                                                        <input type="number" className="form-control" id={`due${item.id}`} defaultValue={item.amount_due} disabled />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="amount_paid" className="col-form-label">{translate(`amount_paid_${lang}`)}:</label>
                                                                        <input type="number" className="form-control" name="amount_paid" id={`paid${item.id}`} step="any" defaultValue={0} onChange={(e) => {
                                                                            if (e.target.value > item.amount_due) {
                                                                                $(`#paid${item.id}`).val(item.amount_due);
                                                                                $(`#due${item.id}`).val(0);
                                                                            } else {
                                                                                $(`#due${item.id}`).val(item.amount_due - e.target.value);
                                                                            }
                                                                        }} required />
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                        <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                        <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id="group" tabIndex={-1} aria-labelledby="group" aria-hidden="true">
                                                    <div className="modal-dialog modal-xl">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`group_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" id="partners-tab" data-bs-toggle="tab" href="#partners" role="tab" aria-controls="partners-tab" aria-selected="false">{translate(`partners_${lang}`)}</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="production-tab" data-bs-toggle="tab" href="#production" role="tab" aria-controls="production-tab" aria-selected="false">{translate(`productions_${lang}`)}</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="transfer-tab" data-bs-toggle="tab" href="#transfer" role="tab" aria-controls="transfer-tab" aria-selected="false">{translate(`transfer_${lang}`)}</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="service-tab" data-bs-toggle="tab" href="#service" role="tab" aria-controls="service-tab" aria-selected="false">{translate(`receipt_${lang}`)}</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="payment-tab" data-bs-toggle="tab" href="#payment" role="tab" aria-controls="payment-tab" aria-selected="false">{translate(`contract_payment_${lang}`)}</a>
                                                                    </li>
                                                                </ul>
                                                                <div className="tab-content border border-top-0 p-3" id="myTabContent">
                                                                    <div className="tab-pane fade show active" id="partners" role="tabpanel" aria-labelledby="partners-tab">
                                                                        <table className="table table-striped table-sm contract-tbl" style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`partner_${lang}`)}</th>
                                                                                    <th>{translate(`percent_${lang}`)}</th>
                                                                                    <th>{translate(`note_${lang}`)}</th>
                                                                                    <th>{translate(`date_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {thisContract.map((i, x) => (
                                                                                    <tr key={x}>
                                                                                        <td>{x + 1}</td>
                                                                                        <td>{i[`safe_name_${lang}`]}</td>
                                                                                        <td>{i.rate}%</td>
                                                                                        <td>{i.note}</td>
                                                                                        <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="production" role="tabpanel" aria-labelledby="production-tab">
                                                                        <table className="table table-striped table-sm production-tbl" style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`product_${lang}`)}</th>
                                                                                    <th>{translate(`store_${lang}`)}</th>
                                                                                    <th>{translate(`sqm_${lang}`)}</th>
                                                                                    <th>{translate(`productions_${lang}`)}</th>
                                                                                    <th>{translate(`receipt_${lang}`)}</th>
                                                                                    <th>{translate(`total_cost_${lang}`)}</th>
                                                                                    <th>{translate(`attachment_${lang}`)}</th>
                                                                                    <th>{translate(`note_${lang}`)}</th>
                                                                                    <th>{translate(`date_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {thisProductions.map((i, x) => (
                                                                                    <tr key={item.id}>
                                                                                        <td>{x + 1}</td>
                                                                                        <td>{i[`product_name_${lang}`]}</td>
                                                                                        <td>{i[`store_name_${lang}`]}</td>
                                                                                        <td>{commaNumber(i.square_meter)}</td>
                                                                                        <td>{commaNumber(i.product_cost)} $</td>
                                                                                        <td>{commaNumber(i.service_cost)} $</td>
                                                                                        <td>{commaNumber(i.total_cost)} $</td>
                                                                                        <td>{i.attachment ? <a href={`/${i.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                                                                        <td>{i.note ? i.note : "-"}</td>
                                                                                        <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
                                                                        <table className="table table-striped table-sm transfer-tbl" style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`from_store_${lang}`)}</th>
                                                                                    <th>{translate(`to_store_${lang}`)}</th>
                                                                                    <th>{translate(`group_${lang}`)}</th>
                                                                                    <th>{translate(`item_${lang}`)}</th>
                                                                                    <th>{translate(`qty_${lang}`)}</th>
                                                                                    <th>{translate(`height_${lang}`)}</th>
                                                                                    <th>{translate(`width_${lang}`)}</th>
                                                                                    <th>{translate(`weight_${lang}`)}</th>
                                                                                    <th>{translate(`price_${lang}`)}</th>
                                                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {thisTransfer.map((i, x) => (
                                                                                    <tr key={x}>
                                                                                        <td>{x + 1}</td>
                                                                                        <td>{i[`from_store_name_${lang}`]}</td>
                                                                                        <td>{i[`to_store_name_${lang}`]}</td>
                                                                                        <td>{i[`group_name_${lang}`]}</td>
                                                                                        <td>{i[`item_name_${lang}`]}</td>
                                                                                        <td>{i.quantity ? i.quantity : '-'}</td>
                                                                                        <td>{i.height ? i.height : '-'}</td>
                                                                                        <td>{i.width ? i.width : '-'}</td>
                                                                                        <td>{i.weight ? i.weight : '-'}</td>
                                                                                        <td>{i.price} {i.symbol}</td>
                                                                                        <td>{commaNumber(i.total_price)} {i.symbol}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service-tab">
                                                                        <table className="table table-striped table-sm service-tbl" style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`single_contract_${lang}`)}</th>
                                                                                    <th>{translate(`note_${lang}`)}</th>
                                                                                    <th>{translate(`cost_${lang}`)}</th>
                                                                                    <th>{translate(`date_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {thisServices.map((i, x) => (
                                                                                    <tr key={x}>
                                                                                        <td>{x + 1}</td>
                                                                                        <td>{i[`title_${lang}`]}</td>
                                                                                        <td>{i.description}</td>
                                                                                        <td>{i.cost} {i.symbol}</td>
                                                                                        <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                                                        <table className="table table-striped table-sm payment-tbl" style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>{translate(`customers_${lang}`)}</th>
                                                                                    <th>{translate(`paid_${lang}`)}</th>
                                                                                    <th>{translate(`due_${lang}`)}</th>
                                                                                    <th>{translate(`date_${lang}`)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {thisPayments.map((i, x) => (
                                                                                    <tr key={item.id}>
                                                                                        <td>{x + 1}</td>
                                                                                        <td>{i[`full_name_${lang}`]}</td>
                                                                                        <td>{commaNumber(i.amount_paid)} {i.symbol}</td>
                                                                                        <td>{commaNumber(i.amount_due)} {i.symbol}</td>
                                                                                        <td>{new Date(i.created).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default List;